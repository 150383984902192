var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "overflow-hidden" },
    [
      _c(
        "v-dialog",
        {
          staticClass: "overflow-hidden",
          attrs: {
            "max-width": "500",
            fullscreen: !_vm.$vuetify.breakpoint.mdAndUp,
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "pb-4 overflow-hidden",
              attrs: { "max-width": "500" },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "px-2 py-1" },
                [
                  _c(
                    "v-row",
                    { staticClass: "ma-0 ml-4" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "col-10 px-0 grey--text text--darken-4 text-h6 text-truncate",
                        },
                        [_vm._v(_vm._s(_vm.$t("customQuoteRequest")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "col-2 my-auto",
                          attrs: { "aria-label": "cross", icon: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog")
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-8", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "subtitle-1 font-weight-medium grey--text text--darken-4",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("companyInformation")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyNameErrors,
                              label: _vm.$t("companyName"),
                              outlined: "",
                              required: "",
                              disabled: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyName.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyName.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyName,
                              callback: function ($$v) {
                                _vm.companyName = $$v
                              },
                              expression: "companyName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyPhoneErrors,
                              label: _vm.$t("companyPhone"),
                              disabled: "",
                              outlined: "",
                              required: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyPhone.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyPhone.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyPhone,
                              callback: function ($$v) {
                                _vm.companyPhone = $$v
                              },
                              expression: "companyPhone",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyEmailErrors,
                              label: _vm.$t("companyEmail"),
                              disabled: "",
                              outlined: "",
                              required: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.companyEmail.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.companyEmail.$touch()
                              },
                            },
                            model: {
                              value: _vm.companyEmail,
                              callback: function ($$v) {
                                _vm.companyEmail = $$v
                              },
                              expression: "companyEmail",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mt-8", attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass:
                            "subtitle-1 font-weight-medium grey--text text--darken-4",
                          attrs: { cols: "12" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Contact Information")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "billing-address-card rounded-lg",
                              attrs: {
                                elevation: "0",
                                color: "#F5F5F5",
                                outlined: "",
                              },
                            },
                            [
                              _c(
                                "v-card-subtitle",
                                {
                                  staticClass:
                                    "body-2 font-weight-regular grey--text text--darken-4",
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      _vm._s(this.$store.state.User.user.name)
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("phone")) +
                                        ": " +
                                        _vm._s(
                                          this.$store.state.User.user.phone
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("email")) +
                                        ": " +
                                        _vm._s(
                                          this.$store.state.User.user.email
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.companyUsersErrors,
                              label: _vm.$t("users"),
                              outlined: "",
                              hint: _vm.$t("enterValueGreaterThan"),
                              "persistent-hint": "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.users.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.users.$touch()
                              },
                            },
                            model: {
                              value: _vm.users,
                              callback: function ($$v) {
                                _vm.users = $$v
                              },
                              expression: "users",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-textarea", {
                            attrs: {
                              label: "Add Comments",
                              rows: "2",
                              outlined: "",
                              dense: "",
                              required: "",
                            },
                            model: {
                              value: _vm.comments,
                              callback: function ($$v) {
                                _vm.comments = $$v
                              },
                              expression: "comments",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "mb-2 mr-4" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none mr-3",
                          attrs: {
                            outlined: "",
                            color: "primary",
                            width: !_vm.$vuetify.breakpoint.mdAndUp
                              ? "46%"
                              : "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none white--text",
                          attrs: {
                            color: "#F26227",
                            width: !_vm.$vuetify.breakpoint.mdAndUp
                              ? "46%"
                              : "",
                            disabled: _vm.$v.$invalid,
                          },
                          on: { click: _vm.saveCompanyDetails },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("requestQuote")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }