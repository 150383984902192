<template>
  <div class="overflow-hidden">
    <v-dialog
      v-model="dialog"
      max-width="500"
      :fullscreen="!$vuetify.breakpoint.mdAndUp"
      class="overflow-hidden"
    >
      <v-card max-width="500" class="pb-4 overflow-hidden">
        <v-card-title class="px-2 py-1">
          <v-row class="ma-0 ml-4">
            <span
              class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
              >{{ $t("customQuoteRequest") }}</span
            >
            <v-btn
              class="col-2 my-auto"
              aria-label="cross"
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text>
          <v-row no-gutters class="mt-8">
            <v-col
              cols="12"
              class="subtitle-1 font-weight-medium grey--text text--darken-4"
            >
              {{ $t("companyInformation") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyName"
                :error-messages="companyNameErrors"
                :label="$t('companyName')"
                outlined
                required
                disabled
                @input="$v.companyName.$touch()"
                @blur="$v.companyName.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyPhone"
                :error-messages="companyPhoneErrors"
                :label="$t('companyPhone')"
                disabled
                outlined
                required
                @input="$v.companyPhone.$touch()"
                @blur="$v.companyPhone.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="companyEmail"
                :error-messages="companyEmailErrors"
                :label="$t('companyEmail')"
                disabled
                outlined
                required
                @input="$v.companyEmail.$touch()"
                @blur="$v.companyEmail.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters class="mt-8">
            <v-col
              cols="12"
              class="subtitle-1 font-weight-medium grey--text text--darken-4"
            >
              {{ $t("Contact Information") }}
            </v-col>
          </v-row>
          <v-row class="mb-4">
            <v-col cols="12" class="py-0">
              <v-card
                elevation="0"
                color="#F5F5F5"
                outlined
                class="billing-address-card rounded-lg"
              >
                <v-card-subtitle
                  class="body-2 font-weight-regular grey--text text--darken-4"
                >
                  <div>{{ this.$store.state.User.user.name }}</div>
                  <div>
                    {{ $t("phone") }}: {{ this.$store.state.User.user.phone }}
                  </div>
                  <div>
                    {{ $t("email") }}: {{ this.$store.state.User.user.email }}
                  </div>
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="users"
                :error-messages="companyUsersErrors"
                :label="$t('users')"
                outlined
                :hint="$t('enterValueGreaterThan')"
                persistent-hint
                @input="$v.users.$touch()"
                @blur="$v.users.$touch()"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="comments"
                label="Add Comments"
                rows="2"
                outlined
                dense
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-2 mr-4">
          <v-row justify="end">
            <v-btn
              outlined
              color="primary"
              :width="!$vuetify.breakpoint.mdAndUp ? '46%' : ''"
              class="text-none mr-3"
              @click="$emit('closeDialog')"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="#F26227"
              :width="!$vuetify.breakpoint.mdAndUp ? '46%' : ''"
              class="text-none white--text"
              :disabled="$v.$invalid"
              @click="saveCompanyDetails"
            >
              {{ $t("requestQuote") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
);
const user = helpers.regex("serial", /^(?:1[1-9]|[2-9]\d|[1-9]\d{2,})$/);
export default {
  mixins: [validationMixin],
  validations: {
    companyName: {
      required,
      maxLength: maxLength(80),
      minLength: minLength(1),
    },
    companyPhone: { required, phone },
    companyEmail: { required, email },
    users: {
      required,
      user,
    },
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    companyInformation: {
      type: Object,
      default: () => {},
    },
    isAddUser: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      companyName: "",
      companyPhone: "",
      companyEmail: "",
      users: "",
      comments: "",
    };
  },
  computed: {
    companyUsersErrors() {
      const errors = [];
      if (!this.$v.users.$dirty) return errors;
      !this.$v.users.user && errors.push(this.$t("usersValidError"));
      !this.$v.users.required && errors.push(this.$t("usersValidError"));
      return errors;
    },
    companyNameErrors() {
      const errors = [];
      if (!this.$v.companyName.$dirty) return errors;
      !this.$v.companyName.maxLength &&
        errors.push(this.$t("companyNameLengthError"));
      !this.$v.companyName.required &&
        errors.push(this.$t("companyNameRequiredError"));
      return errors;
    },
    companyPhoneErrors() {
      const errors = [];
      if (!this.$v.companyPhone.$dirty) return errors;
      !this.$v.companyPhone.phone &&
        errors.push(this.$t("companyPhoneValidError"));
      !this.$v.companyPhone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    companyEmailErrors() {
      const errors = [];
      if (!this.$v.companyEmail.$dirty) return errors;
      !this.$v.companyEmail.email &&
        errors.push(this.$t("companyEmailValidError"));
      !this.$v.companyEmail.required &&
        errors.push(this.$t("emailRequiredError"));
      return errors;
    },
  },
  created() {
    this.companyName = this.companyInformation.name;
    this.companyEmail = this.companyInformation.email;
    this.companyPhone = this.companyInformation.phone;
  },
  methods: {
    async saveCompanyDetails() {
      let companyData = this.companyInformation;
      companyData.options = { settings: { mfa: true }, eula: companyData.eula };
      companyData.users = this.users;
      companyData.customQuoteComments = this.comments;
      if (companyData.centers && !this.isAddUser)
        companyData.centers = JSON.parse(companyData.centers);
      this.$emit("quoteSent", companyData);
    },
  },
};
</script>

<style lang="scss" scoped></style>
